import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Login from './components/Login';
import ConsignmentList from './components/ConsignmentList/ConsignmentList';
import ConsignmentView from './components/ConsignmentView/ConsignmentView';
import PrivateRoute from './components/PrivateRoute';
import Navbar from './components/Navbar';
import Layout from './components/Layout/Layout';

function App() {
    return (
        <Router>
            <AuthProvider>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route
                        element={
                            <PrivateRoute>
                                <>
                                    <Navbar />
                                    <Layout>
                                        <Outlet />
                                    </Layout>
                                </>
                            </PrivateRoute>
                        }
                    >
                        <Route path="/" element={<Navigate to="/consignments" replace />} />
                        <Route path="/consignments" element={<ConsignmentList />} />
                        <Route path="/consignments/:id" element={<ConsignmentView />} />
                    </Route>
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </AuthProvider>
        </Router>
    );
}

export default App;
