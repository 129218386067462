import axios from 'axios';

const API_URL = '/api';

const api = {
    listConsignments: async (status) => {
        try {
            console.log('Fetching consignments with status:', status);
            const response = await axios.get(`${API_URL}/consignments`, {
                params: { status }
            });
            return response;
        } catch (error) {
            console.error('API Error:', error);
            throw error;
        }
    },

    getConsignment: async (id) => {
        try {
            const response = await axios.get(`${API_URL}/consignments/${id}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching consignment:', error);
            throw error;
        }
    },

    createConsignment: async (consignmentData) => {
        const url = `${API_URL}/consignments`;
        
        console.log('API Request Details:', {
            method: 'POST',
            url: url,
            headers: {
                'Content-Type': 'application/json'
            },
            payload: consignmentData
        });

        try {
            const response = await axios.post(url, consignmentData);
            console.log('API Response:', response);
            return response;
        } catch (error) {
            console.error('API Error:', {
                url: url,
                status: error.response?.status,
                data: error.response?.data,
                message: error.message
            });
            throw error;
        }
    },

    updateConsignment: async (id, data) => {
        const response = await axios.put(`${API_URL}/consignments/${id}`, data);
        return response.data;
    },

    listSuppliers: async () => {
        try {
            const response = await axios.get(`${API_URL}/suppliers`);
            return response.data;
        } catch (error) {
            console.error('Error fetching suppliers:', error);
            throw error;
        }
    },

    addProductToConsignment: async (consignmentId, productId, count) => {
        try {
            // First check if product already exists in consignment
            const existingProducts = await api.getConsignmentProducts(consignmentId);
            const existingProduct = existingProducts.data?.find(
                product => product.product_id === productId
            );

            if (existingProduct) {
                // Add the new count to the existing count
                const newCount = parseInt(existingProduct.count) + parseInt(count);
                return await api.updateConsignmentProductQuantity(
                    consignmentId,
                    productId,
                    newCount
                );
            } else {
                // Add new product
                const response = await axios.post(
                    `${API_URL}/consignments/${consignmentId}/products`,
                    {
                        product_id: productId,
                        count: parseInt(count) || 1 // Ensure it's a number
                    }
                );
                return response;
            }
        } catch (error) {
            console.error('Error adding/updating product:', error);
            throw error;
        }
    },

    getConsignmentProducts: async (consignmentId) => {
        try {
            console.log('Fetching products for consignment:', consignmentId);
            const response = await axios.get(
                `${API_URL}/consignments/${consignmentId}/products`,
                {
                    params: { page_size: 500 }
                }
            );
            
            console.log('Raw consignment products response:', response);

            if (!response?.data) {
                return { data: [] };
            }

            return response.data;
        } catch (error) {
            console.error('Error fetching consignment products:', error);
            throw error;
        }
    },

    lookupProductByBarcode: async (barcode) => {
        try {
            if (!barcode) {
                throw new Error('No barcode provided for lookup');
            }

            const url = `${API_URL}/products?sku=${barcode.trim()}`;
            console.log('Looking up product at URL:', url);

            const response = await axios.get(url);
            console.log('Lookup response:', response);

            return response;
        } catch (error) {
            console.error('Full error:', error);
            console.error('API URL used:', `${API_URL}/products?sku=${barcode}`);
            throw error;
        }
    },

    getProductById: async (productId) => {
        try {
            console.log('Looking up product by ID:', productId);
            const response = await axios.get(`${API_URL}/products/${productId}`);
            
            console.log('Product details response:', response);
            return response;
        } catch (error) {
            console.error('Error looking up product by ID:', error);
            throw error;
        }
    },

    updateConsignmentProductQuantity: async (consignmentId, productId, quantity) => {
        try {
            const response = await axios.put(
                `${API_URL}/consignments/${consignmentId}/products/${productId}`,
                { count: parseInt(quantity) || 0 } // Ensure it's a number
            );
            return response.data;
        } catch (error) {
            console.error('Error updating quantity:', error);
            throw error;
        }
    },

    deleteConsignmentProduct: async (consignmentId, productId) => {
        try {
            const response = await axios.delete(
                `${API_URL}/consignments/${consignmentId}/products/${productId}`
            );
            return response.data;
        } catch (error) {
            console.error('Error deleting product:', error);
            throw error;
        }
    },

    updateConsignmentReference: async (id, reference) => {
        return axios.put(`${API_URL}/consignments/${id}`, {
            reference: reference
        });
    },

    updateConsignmentStatus: async (id, status) => {
        console.log(`API call: Update consignment ${id} status to ${status}`);
        const response = await axios.put(`${API_URL}/consignments/${id}`, { status });
        console.log('API response:', response);
        return response;
    },

    updateConsignmentProductReceived: async (consignmentId, productId, receivedQty) => {
        console.log(`API call: Update received quantity for product ${productId} to ${receivedQty}`);
        const response = await axios.put(
            `${API_URL}/consignments/${consignmentId}/products/${productId}`,
            { received: receivedQty }
        );
        console.log('API response:', response);
        return response;
    },

    deleteConsignment: async (id) => {
        console.log('API deleteConsignment called with ID:', id);
        
        const url = `${API_URL}/consignments/${id}`;
        console.log('Delete request URL:', url);
        
        try {
            const response = await axios.delete(url);
            console.log('Delete API success:', {
                status: response.status,
                data: response.data
            });
            return response;
        } catch (error) {
            console.error('Delete API error:', {
                url: url,
                status: error.response?.status,
                data: error.response?.data,
                message: error.message
            });
            throw error;
        }
    },

    updateConsignmentName: async (id, name) => {
        return await axios.put(`${API_URL}/consignments/${id}`, { name });
    },

    getConsignments: async (status) => {
        return await axios.get(`/api/2.0/consignments?status=${status}`);
    },

    markConsignmentAsSent: async (id) => {
        return axios.put(`${API_URL}/consignments/${id}`, { status: 'SENT' });
    },

    markConsignmentAsDispatched: async (id) => {
        return axios.put(`${API_URL}/consignments/${id}`, { status: 'DISPATCHED' });
    },

    updateReceivedQuantity: async (consignmentId, productId, data) => {
        return axios.put(
            `/api/consignments/${consignmentId}/products/${productId}/received`,
            data
        );
    },

    finalizeReceiving: async (id) => {
        console.log('Finalizing consignment:', id);
        return axios.put(`${API_URL}/consignments/${id}`, {
            status: 'RECEIVED'
        });
    },

    updateAllReceivedQuantities: async (consignmentId) => {
        console.log('Updating all received quantities for consignment:', consignmentId);
        const response = await axios.put(
            `${API_URL}/consignments/${consignmentId}/receive-quantities`,
            {}
        );
        return response;
    }
};

export default api;
