import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyA_uFt1YucgSZVWrJ5jrVtwv8DIQmm4s9w",
    authDomain: "ls-scanner-stock-app.firebaseapp.com",
    projectId: "ls-scanner-stock-app",
    storageBucket: "ls-scanner-stock-app.firebasestorage.app",
    messagingSenderId: "59214068866",
    appId: "1:59214068866:web:b9699d4f6a747531c8c89b"
  };
  
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app; 