import React from 'react';
import { Box, Container } from '@mui/material';

const Layout = ({ children }) => {
    return (
        <Box>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                {children}
            </Container>
        </Box>
    );
};

export default Layout;
