import React, { useState, useEffect } from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Box,
    CircularProgress,
    Alert,
    Tooltip,
    TableSortLabel,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Typography
} from '@mui/material';
import ConsignmentForm from '../ConsignmentForm/ConsignmentForm';
import api from '../../services/api';
import { useNavigate, useLocation } from 'react-router-dom';
import SuccessNotification from '../SuccessNotification/SuccessNotification';

const ConsignmentList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [consignments, setConsignments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [orderBy, setOrderBy] = useState('created_at');
    const [order, setOrder] = useState('desc');
    const [successMessage, setSuccessMessage] = useState('');
    const [statusFilter, setStatusFilter] = useState('OPEN');

    const STATUSES = [
        'OPEN',
        'SENT',
        'DISPATCHED',
        'RECEIVED',
        'CANCELLED',
        'CLOSED'
    ];

    useEffect(() => {
        loadConsignments();
    }, []);

    useEffect(() => {
        if (location.state?.deleteMessage) {
            setSuccessMessage(location.state.deleteMessage);
        }
    }, [location]);

    const loadConsignments = async () => {
        setLoading(true);
        try {
            console.log('Fetching consignments with status:', statusFilter);
            const response = await api.listConsignments(statusFilter);
            console.log('API Response:', response);
            setConsignments(response?.data?.data || []);
            setError(null);
        } catch (error) {
            console.error('Error loading consignments:', error);
            setError(`Failed to load consignments: ${error.message}`);
            setConsignments([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadConsignments();
    }, [statusFilter]);

    const handleFormSuccess = (response) => {
        loadConsignments();
    };

    const handleViewConsignment = (consignmentId) => {
        navigate(`/consignments/${consignmentId}`);
    };

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedConsignments = React.useMemo(() => {
        if (!Array.isArray(consignments)) return [];
        
        return [...consignments].sort((a, b) => {
            let aValue = a[orderBy];
            let bValue = b[orderBy];

            if (orderBy === 'created_at') {
                aValue = new Date(aValue).getTime();
                bValue = new Date(bValue).getTime();
            }

            if (!aValue) return 1;
            if (!bValue) return -1;

            if (order === 'asc') {
                return aValue < bValue ? -1 : 1;
            } else {
                return bValue < aValue ? -1 : 1;
            }
        });
    }, [consignments, order, orderBy]);

    const filteredConsignments = React.useMemo(() => {
        if (!Array.isArray(sortedConsignments)) return [];
        return sortedConsignments.filter(consignment => 
            statusFilter ? consignment.status === statusFilter : true
        );
    }, [sortedConsignments, statusFilter]);

    const SortableTableCell = ({ id, label }) => (
        <TableCell>
            <TableSortLabel
                active={orderBy === id}
                direction={orderBy === id ? order : 'asc'}
                onClick={() => handleRequestSort(id)}
            >
                {label}
            </TableSortLabel>
        </TableCell>
    );

    const handleRowClick = (consignmentId) => {
        navigate(`/consignments/${consignmentId}`);
    };

    return (
        <Box>
            <SuccessNotification 
                open={!!successMessage}
                message={successMessage}
                onClose={() => setSuccessMessage('')}
            />
            
            <Box sx={{ 
                mb: 3, 
                display: 'flex', 
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: 'space-between',
                alignItems: { xs: 'stretch', sm: 'center' },
                gap: 2
            }}>
                <FormControl sx={{ minWidth: { xs: '100%', sm: 200 } }}>
                    <InputLabel id="status-filter-label">Status</InputLabel>
                    <Select
                        labelId="status-filter-label"
                        value={statusFilter}
                        label="Status"
                        onChange={(e) => {
                            setStatusFilter(e.target.value);
                        }}
                        size="small"
                    >
                        {STATUSES.map(status => (
                            <MenuItem key={status} value={status}>
                                {status}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setIsFormOpen(true)}
                    sx={{ width: { xs: '100%', sm: 'auto' } }}
                >
                    NEW CONSIGNMENT
                </Button>
            </Box>

            <ConsignmentForm 
                open={isFormOpen}
                onClose={() => setIsFormOpen(false)}
                onSuccess={handleFormSuccess}
            />

            {loading ? (
                <CircularProgress />
            ) : (
                <TableContainer 
                    component={Paper}
                    sx={{
                        overflow: 'auto',
                        '& table': {
                            minWidth: { xs: '800px', md: '100%' }
                        }
                    }}
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <SortableTableCell id="name" label="Name" />
                                <SortableTableCell id="reference" label="Number" />
                                <SortableTableCell id="type" label="Type" />
                                <SortableTableCell id="status" label="Status" />
                                <SortableTableCell id="created_at" label="Created At" />
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredConsignments.map((consignment) => (
                                <TableRow 
                                    key={consignment.id}
                                    onClick={() => handleRowClick(consignment.id)}
                                    sx={{
                                        cursor: 'pointer',
                                        '&:hover': {
                                            backgroundColor: 'action.hover',
                                            transition: 'background-color 0.2s ease'
                                        }
                                    }}
                                >
                                    <TableCell sx={{ 
                                        whiteSpace: 'nowrap',
                                        maxWidth: { xs: '120px', md: '200px' },
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>
                                        <Tooltip title={consignment.id} placement="top">
                                            <span>{consignment.name || 'Unnamed'}</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell sx={{ 
                                        whiteSpace: 'nowrap',
                                        maxWidth: { xs: '120px', md: '200px' },
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>
                                        {consignment.reference || '-'}
                                    </TableCell>
                                    <TableCell sx={{ 
                                        whiteSpace: 'nowrap',
                                        maxWidth: { xs: '120px', md: '200px' },
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>
                                        {consignment.type}
                                    </TableCell>
                                    <TableCell sx={{ 
                                        whiteSpace: 'nowrap',
                                        maxWidth: { xs: '120px', md: '200px' },
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>
                                        {consignment.status}
                                    </TableCell>
                                    <TableCell sx={{ 
                                        whiteSpace: 'nowrap',
                                        maxWidth: { xs: '120px', md: '200px' },
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}>
                                        {new Date(consignment.created_at).toLocaleDateString()}
                                    </TableCell>
                                    <TableCell>
                                        <Button 
                                            size="small" 
                                            variant="outlined"
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevent row click when clicking button
                                                handleViewConsignment(consignment.id);
                                            }}
                                        >
                                            View
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {filteredConsignments.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={6} align="center" sx={{ py: 3 }}>
                                        <Typography color="text.secondary">
                                            No consignments found with status: {statusFilter}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    );
};

export default ConsignmentList;
