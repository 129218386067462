import React, { useEffect, useRef } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Box
} from '@mui/material';
import { keyframes } from '@mui/system';

// Define shake animation
const shakeAnimation = keyframes`
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
`;

const ErrorPopup = ({ 
    open, 
    message, 
    onClose, 
    onConfirm, 
    type = 'error',
    title,
    showCancel = false 
}) => {
    const dialogRef = useRef(null);

    // Handle keydown events
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (open) {
                if (e.key === 'Enter' && type === 'error') {
                    // Prevent Enter from closing error popups
                    e.preventDefault();
                    return;
                }
                
                if (e.key === 'Escape' || e.key === ' ') {
                    onClose();
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [open, type, onClose]);

    const handleConfirm = () => {
        if (onConfirm) {
            onConfirm();
        }
        onClose();
    };

    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            ref={dialogRef}
            PaperProps={{
                sx: {
                    // Apply shake animation and red border for errors
                    ...(type === 'error' && {
                        animation: `${shakeAnimation} 0.5s`,
                        border: '2px solid',
                        borderColor: 'error.main',
                    }),
                    // Different styling for warnings
                    ...(type === 'warning' && {
                        border: '2px solid',
                        borderColor: 'warning.main',
                    })
                }
            }}
        >
            <DialogTitle sx={{
                bgcolor: type === 'error' ? 'error.light' : 
                         type === 'warning' ? 'warning.light' : 'background.paper',
                color: type === 'error' ? 'error.contrastText' : 
                       type === 'warning' ? 'warning.contrastText' : 'text.primary'
            }}>
                {title || (type === 'warning' ? 'Warning' : 'Error')}
            </DialogTitle>
            <DialogContent>
                <Box sx={{ pt: 2 }}>
                    <Typography>{message}</Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                {type === 'warning' ? (
                    // Warning dialog with Yes/No buttons
                    <>
                        <Button 
                            onClick={onClose} 
                            color="primary"
                            variant="outlined"
                        >
                            No
                        </Button>
                        <Button 
                            onClick={handleConfirm} 
                            color="warning" 
                            variant="contained"
                            autoFocus
                        >
                            Yes
                        </Button>
                    </>
                ) : (
                    // Error dialog with OK button
                    <Button 
                        onClick={onClose} 
                        color="error" 
                        variant="contained"
                    >
                        OK
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default ErrorPopup;
