import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Alert,
    CircularProgress,
    Autocomplete
} from '@mui/material';
import api from '../../services/api';

const ConsignmentForm = ({ open, onClose, onSuccess }) => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        type: 'SUPPLIER',
        reference: '',
        consignment_date: new Date().toISOString().split('T')[0],
        supplier_id: null
    });
    const [suppliers, setSuppliers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingSuppliers, setLoadingSuppliers] = useState(false);
    const [error, setError] = useState(null);
    const [selectedSupplier, setSelectedSupplier] = useState(null);

    // Fetch suppliers when form opens
    useEffect(() => {
        if (open) {
            loadSuppliers();
        }
    }, [open]);

    const loadSuppliers = async () => {
        setLoadingSuppliers(true);
        try {
            const response = await api.listSuppliers();
            setSuppliers(response.data || []);
            setError(null);
        } catch (err) {
            console.error('Error loading suppliers:', err);
            setError('Failed to load suppliers - you can continue without selecting a supplier');
        } finally {
            setLoadingSuppliers(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSupplierChange = (event, newValue) => {
        setSelectedSupplier(newValue);
        setFormData(prev => ({
            ...prev,
            supplier_id: newValue?.id || null
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        
        const consignmentData = {
            name: formData.reference,
            reference: formData.reference,
            type: formData.type,
            status: "OPEN",
            outlet_id: "06f2e29c-25cb-11ee-ea12-b003e5dfeaf0",
            supplier_id: formData.supplier_id,
            consignment_date: formData.consignment_date
        };
        
        try {
            const response = await api.createConsignment(consignmentData);
            console.log('Form Submit Response:', response);

            // Check if the ID is present in the correct location
            const consignmentId = response?.data?.data?.id;
            if (!consignmentId) {
                throw new Error('Consignment ID not found in response');
            }

            onSuccess(response);
            onClose();
            // Navigate to the new consignment
            navigate(`/consignments/${consignmentId}`);
        } catch (error) {
            console.error('Form Submit Error:', error);
            setError(error.response?.data?.message || error.message || 'Failed to create consignment');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog 
            open={open} 
            onClose={onClose} 
            maxWidth="sm" 
            fullWidth
            sx={{
                '& .MuiDialog-paper': {
                    margin: { xs: 2, sm: 4 },
                    width: { xs: 'calc(100% - 32px)', sm: '600px' }
                }
            }}
        >
            <DialogTitle>New Consignment</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent sx={{ p: { xs: 2, sm: 3 } }}>
                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {typeof error === 'string' ? error : 'An error occurred'}
                        </Alert>
                    )}
                    <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        gap: { xs: 1.5, sm: 2 }
                    }}>
                        <FormControl fullWidth>
                            <InputLabel>Type</InputLabel>
                            <Select
                                name="type"
                                value={formData.type}
                                onChange={handleChange}
                                label="Type"
                                required
                            >
                                <MenuItem value="SUPPLIER">Supplier</MenuItem>
                                <MenuItem value="STOCKTAKE">Stocktake</MenuItem>
                                <MenuItem value="RETURN">Return</MenuItem>
                            </Select>
                        </FormControl>

                        {formData.type === 'SUPPLIER' && (
                            <Autocomplete
                                options={suppliers}
                                getOptionLabel={(option) => option.name || ''}
                                loading={loadingSuppliers}
                                value={selectedSupplier}
                                onChange={handleSupplierChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Supplier (Optional)"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {loadingSuppliers ? (
                                                        <CircularProgress color="inherit" size={20} />
                                                    ) : null}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        )}

                        <TextField
                            name="reference"
                            label="PO Number / Reference"
                            value={formData.reference}
                            onChange={handleChange}
                            fullWidth
                            required
                        />

                        <TextField
                            name="consignment_date"
                            label="Consignment Date"
                            type="date"
                            value={formData.consignment_date}
                            onChange={handleChange}
                            fullWidth
                            required
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions sx={{ 
                    p: { xs: 2, sm: 3 },
                    flexDirection: { xs: 'column', sm: 'row' },
                    '& > button': {
                        width: { xs: '100%', sm: 'auto' }
                    }
                }}>
                    <Button 
                        onClick={onClose}
                        sx={{ mb: { xs: 1, sm: 0 } }}
                    >
                        Cancel
                    </Button>
                    <Button 
                        type="submit" 
                        variant="contained" 
                        disabled={loading}
                    >
                        {loading ? 'Creating...' : 'Create Consignment'}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default ConsignmentForm;
