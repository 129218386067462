import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box,
    Paper,
    Typography,
    Button,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CircularProgress,
    Alert,
    IconButton,
    TextField,
    Divider,
    Collapse,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Chip,
    LinearProgress,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import api from '../../services/api';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorPopup from '../ErrorPopup/ErrorPopup';
import axios from 'axios';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HistoryIcon from '@mui/icons-material/History';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Papa from 'papaparse';

const styles = {
    '@keyframes fadeIn': {
        from: {
            opacity: 0,
            transform: 'translateY(-20px)'
        },
        to: {
            opacity: 1,
            transform: 'translateY(0)'
        }
    },
    '@keyframes shake': {
        '0%, 100%': {
            transform: 'translateX(0)',
        },
        '25%': {
            transform: 'translateX(-4px)',
        },
        '75%': {
            transform: 'translateX(4px)',
        }
    }
};

const getParentSku = (sku) => {
    if (!sku) return '';
    return sku.split('-').slice(0, -1).join('-');
};

const consolidateProducts = (products) => {
    if (!Array.isArray(products)) return [];
    
    const consolidated = {};
    
    products.forEach(product => {
        if (!product?.sku) {
            console.warn('Product missing SKU:', product);
            return;
        }

        const parentSku = getParentSku(product.sku);
        if (!parentSku) {
            console.warn('Invalid SKU format:', product.sku);
            return;
        }

        if (!consolidated[parentSku]) {
            consolidated[parentSku] = {
                parentSku,
                name: product.name || 'Unknown Product',
                sizes: {},
                baseProduct: product
            };
        }

        const size = product.size || product.variant_options?.[0]?.value || 'ONE SIZE';
        consolidated[parentSku].sizes[size] = {
            count: product.count || 0,
            id: product.id,
            status: product.status || 'UNKNOWN',
            sku: product.sku
        };
    });

    return Object.values(consolidated);
};

const UploadProgressDialog = ({ isUploading, uploadStatus, uploadProgress }) => {
    console.log('UploadProgressDialog render:', { isUploading, uploadStatus, uploadProgress });
    
    return (
        <Dialog 
            open={isUploading} 
            maxWidth="sm" 
            fullWidth
        >
            <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>
                Processing File
            </DialogTitle>
            <DialogContent sx={{ bgcolor: 'background.paper' }}>
                <Box sx={{ p: 2 }}>
                    <Typography variant="body2" gutterBottom>
                        {uploadStatus}
                    </Typography>
                    <LinearProgress 
                        variant="determinate" 
                        value={uploadProgress} 
                        sx={{ my: 2 }}
                    />
                    <Typography variant="caption" color="text.secondary">
                        {Math.round(uploadProgress)}% Complete
                    </Typography>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

const ConsignmentView = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [consignment, setConsignment] = useState(null);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [lastModifiedId, setLastModifiedId] = useState(null);
    const barcodeInputRef = useRef(null);
    const [barcode, setBarcode] = useState('');
    const [tempQuantities, setTempQuantities] = useState({});
    const [errorPopup, setErrorPopup] = useState({ 
        open: false, 
        message: '', 
        type: 'error',
        onConfirm: null 
    });
    const [isEditing, setIsEditing] = useState(false);
    const [tempReference, setTempReference] = useState('');
    const [isSaved, setIsSaved] = useState(true);
    const tableRef = useRef(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [showDebugSteps, setShowDebugSteps] = useState(false);
    const [finalizingMessage, setFinalizingMessage] = useState('');
    const [headerExpanded, setHeaderExpanded] = useState(false);
    const [scanHistory, setScanHistory] = useState([]);
    const [showScanHistory, setShowScanHistory] = useState(false);
    const [barcodeQueue, setBarcodeQueue] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadStatus, setUploadStatus] = useState('');
    const [isUploading, setIsUploading] = useState(false);

    useEffect(() => {
        const loadConsignment = async () => {
            try {
                const response = await api.getConsignment(id);
                console.log('Loaded consignment:', response.data);  // Debug log
                setConsignment(response.data);
            } catch (err) {
                console.error('Error loading consignment:', err);
                setError('Failed to load consignment details');
            }
        };

        loadConsignment();
        loadConsignmentAndProducts();
    }, [id]);

    const loadConsignmentAndProducts = async () => {
        try {
            setLoading(true);
            
            // Get products in consignment
            const productsResponse = await api.getConsignmentProducts(id);
            console.log('Products response:', productsResponse);

            if (!productsResponse?.data) {
                setProducts([]);
                return;
            }

            // Map through each product and get its details using product_id
            const productsWithDetails = await Promise.all(
                productsResponse.data.map(async (product) => {
                    if (!product?.product_id) {
                        console.warn('Product missing ID:', product);
                        return {
                            id: 'unknown',
                            sku: 'Unknown SKU',
                            name: 'Product details not available',
                            size: '',
                            count: product.count || 0,
                            status: product.status || 'UNKNOWN'
                        };
                    }

                    try {
                        const productDetails = await api.getProductById(product.product_id);
                        const productData = productDetails?.data?.data;
                        
                        if (!productData) {
                            throw new Error('No product data returned from lookup');
                        }

                        return {
                            id: product.product_id,
                            sku: productData.sku || 'Unknown SKU',
                            name: productData.name || productData.name,
                            size: productData.variant_options?.[0]?.value || '',
                            count: product.count,
                            status: product.status
                        };
                    } catch (err) {
                        console.error('Error fetching product details:', err);
                        return {
                            id: product.product_id,
                            sku: 'Error',
                            name: 'Failed to load product details',
                            size: '',
                            count: product.count,
                            status: product.status
                        };
                    }
                })
            );

            console.log('Products with details:', productsWithDetails);
            setProducts(productsWithDetails);
        } catch (err) {
            console.error('Error loading products:', err);
            setError('Failed to load products');
            setProducts([]);
        } finally {
            setLoading(false);
        }
    };

    const handleQuantityInputChange = (productId, value) => {
        setTempQuantities({
            ...tempQuantities,
            [productId]: value
        });
    };

    const handleQuantitySave = async (productId) => {
        const newQuantity = parseInt(tempQuantities[productId]);
        
        if (!isNaN(newQuantity) && newQuantity >= 0) {
            try {
                await api.updateConsignmentProductQuantity(id, productId, newQuantity);
                setProducts(products.map(product => 
                    product.id === productId 
                        ? { ...product, count: newQuantity }
                        : product
                ));
                setLastModifiedId(productId);
                setTempQuantities(prev => {
                    const updated = { ...prev };
                    delete updated[productId];
                    return updated;
                });
            } catch (err) {
                console.error('Error updating quantity:', err);
                setError('Failed to update quantity');
            }
        }
    };

    const handleDeleteProduct = async (parentSku) => {
        try {
            // Find all products with this parent SKU, including those with 0 quantity
            const productsToDelete = products.filter(product => 
                getParentSku(product.sku) === parentSku
            );

            if (productsToDelete.length === 0) {
                console.warn('No products found to delete for SKU:', parentSku);
                setErrorPopup({
                    open: true,
                    message: 'No products found to delete',
                    type: 'error'
                });
                return;
            }

            console.log('Attempting to delete products:', productsToDelete);

            // Delete all associated products, even if they have 0 quantity
            const deletePromises = productsToDelete.map(async product => {
                try {
                    await api.deleteConsignmentProduct(id, product.id);
                    return true;
                } catch (err) {
                    console.warn(`Failed to delete product ${product.id}:`, err);
                    return false;
                }
            });

            // Wait for all deletions to complete
            const results = await Promise.allSettled(deletePromises);
            const successfulDeletes = results.filter(r => r.status === 'fulfilled' && r.value).length;

            // Log the deletion event
            logScanEvent({
                type: 'deleted',
                sku: parentSku,
                message: `Deleted product group (${successfulDeletes}/${productsToDelete.length} items)`
            });

            if (successfulDeletes < productsToDelete.length) {
                // Refresh the entire product list if partial deletion
                await loadConsignmentAndProducts();
                
                setErrorPopup({
                    open: true,
                    message: `Partially deleted: ${successfulDeletes} of ${productsToDelete.length} items. View refreshed.`,
                    type: 'warning'
                });
            } else {
                // If all deletions successful, just update local state
                setProducts(products.filter(product => 
                    getParentSku(product.sku) !== parentSku
                ));
            }

        } catch (err) {
            console.error('Error deleting products:', err);
            setErrorPopup({
                open: true,
                message: 'Failed to delete products. Please try again.',
                type: 'error'
            });
            // Refresh view on error as well
            await loadConsignmentAndProducts();
        }
    };

    const handleProductAdded = async (newProduct) => {
        try {
            console.log('Raw consignment products response:', newProduct);
            
            // Ensure we have the required data
            if (!newProduct?.productDetails) {
                console.warn('Product details missing:', newProduct);
                return;
            }

            const productDetails = newProduct.productDetails;
            
            // Create a properly formatted product object
            const formattedProduct = {
                id: productDetails.id,
                sku: productDetails.sku || '',
                name: productDetails.variant_name || productDetails.name || 'Unknown Product',
                size: productDetails.variant_options?.[0]?.value || 'ONE SIZE',
                count: 1,
                status: productDetails.status || 'PENDING'
            };

            setProducts(prevProducts => {
                // Find existing product with same SKU
                const existingProduct = prevProducts.find(
                    product => product.sku === formattedProduct.sku
                );

                if (existingProduct) {
                    // Update quantity of existing product
                    return prevProducts.map(product => 
                        product.sku === formattedProduct.sku
                            ? { ...product, count: (parseInt(product.count) || 0) + 1 }
                            : product
                    );
                } else {
                    // Add new product to the list
                    return [...prevProducts, formattedProduct];
                }
            });

            // Highlight the modified row
            setLastModifiedId(productDetails.id);

            // Optionally refresh the full product list from the server
            try {
                const response = await api.getConsignmentProducts(id);
                if (response?.data) {
                    const productsWithDetails = await Promise.all(
                        response.data.map(async (product) => {
                            try {
                                const details = await api.getProductById(product.product_id);
                                return {
                                    id: product.product_id,
                                    sku: details.data?.data?.sku || 'Unknown SKU',
                                    name: details.data?.data?.name || details.data?.data?.name || 'Unknown Product',
                                    size: details.data?.data?.variant_options?.[0]?.value || 'ONE SIZE',
                                    count: product.count || 0,
                                    status: product.status || 'PENDING'
                                };
                            } catch (err) {
                                console.warn('Error fetching product details:', err);
                                return null;
                            }
                        })
                    );

                    // Filter out any null values from failed lookups
                    const validProducts = productsWithDetails.filter(Boolean);
                    setProducts(validProducts);
                }
            } catch (err) {
                console.error('Error refreshing products:', err);
                // Don't throw here - we've already updated the local state
            }

            // Show success message
            setSuccessMessage('Product added successfully');
            setTimeout(() => setSuccessMessage(''), 3000);

        } catch (error) {
            console.error('Error adding product:', error);
            setErrorPopup({
                open: true,
                message: 'Failed to add product. Please try again.',
                type: 'error'
            });
        }
    };

    // Calculate summary values
    const totalSkus = products.length;
    const totalQuantity = products.reduce((sum, product) => sum + (parseInt(product.count) || 0), 0);

    // Add function to log scan events
    const logScanEvent = (event) => {
        const newEvent = {
            id: Date.now(),
            timestamp: new Date().toLocaleString(),
            ...event
        };
        setScanHistory(prev => [newEvent, ...prev]);
    };

    // Add a function to process the queue
    const processQueue = async () => {
        if (isProcessing || barcodeQueue.length === 0) return;
        
        setIsProcessing(true);
        const currentBarcode = barcodeQueue[0];
        
        try {
            // Look up product
            const productResponse = await api.lookupProductByBarcode(currentBarcode);
            
            if (!productResponse?.data?.data) {
                logScanEvent({
                    type: 'error',
                    barcode: currentBarcode,
                    message: 'Product not found'
                });
                setErrorPopup({
                    open: true,
                    message: `Product not found: ${currentBarcode}`
                });
                
                // Log aborted items
                const remainingBarcodes = barcodeQueue.slice(1);
                remainingBarcodes.forEach(barcode => {
                    logScanEvent({
                        type: 'aborted',
                        barcode: barcode,
                        message: 'Scan aborted due to previous error'
                    });
                });
                
                // Clear the entire queue
                setBarcodeQueue([]);
                return;
            }

            const productId = productResponse.data.data.id;
            const response = await api.addProductToConsignment(id, productId, 1);
            
            logScanEvent({
                type: 'success',
                barcode: currentBarcode,
                sku: productResponse.data.data.sku,
                message: 'Product added successfully'
            });

            handleProductAdded({
                ...response.data,
                productDetails: productResponse.data.data
            });
            setLastModifiedId(productId);
            
            // Remove only the processed barcode
            setBarcodeQueue(prev => prev.slice(1));
            
        } catch (err) {
            console.error('Error processing barcode:', err);
            logScanEvent({
                type: 'error',
                barcode: currentBarcode,
                message: 'Invalid barcode'
            });
            
            // Log aborted items
            const remainingBarcodes = barcodeQueue.slice(1);
            remainingBarcodes.forEach(barcode => {
                logScanEvent({
                    type: 'aborted',
                    barcode: barcode,
                    message: 'Scan aborted due to previous error'
                });
            });
            
            setErrorPopup({
                open: true,
                message: `Invalid barcode: ${currentBarcode}. Queue processing stopped.`,
                type: 'error'
            });
            
            // Clear the entire queue
            setBarcodeQueue([]);
        } finally {
            setIsProcessing(false);
        }
    };

    // Add useEffect to watch the queue
    useEffect(() => {
        if (barcodeQueue.length > 0 && !isProcessing) {
            processQueue();
        }
    }, [barcodeQueue, isProcessing]);

    // Update handleBarcodeScan to use the queue
    const handleBarcodeScan = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const scannedBarcode = e.target.value.trim();
            if (!scannedBarcode) return;

            // Add to queue instead of processing immediately
            setBarcodeQueue(prev => [...prev, scannedBarcode]);
            setBarcode(''); // Clear input
            barcodeInputRef.current?.focus();
        }
    };

    const handleReferenceEdit = async () => {
        try {
            await api.updateConsignmentReference(id, tempReference);
            setConsignment(prev => ({ ...prev, reference: tempReference }));
            setIsEditing(false);
            setIsSaved(true);
        } catch (err) {
            console.error('Error updating reference:', err);
            setError('Failed to update reference');
        }
    };

    // Add bottom bar actions
    const handleCancel = async () => {
        setErrorPopup({
            open: true,
            message: 'Are you sure you want to cancel this consignment? This action cannot be undone.',
            type: 'warning',
            onConfirm: async () => {
                try {
                    await api.deleteConsignment(id);
                    // Navigate back with success message
                    navigate('/consignments', {
                        state: {
                            deleteMessage: `Order #${consignment.reference}: ${consignment.name} deleted`
                        }
                    });
                } catch (error) {
                    setErrorPopup({
                        open: true,
                        message: 'Failed to delete consignment. Please try again.',
                        type: 'error'
                    });
                }
            },
            showCancel: true
        });
    };

    const handleFinalizeClick = () => {
        setShowConfirmDialog(true);
    };

    const handleFinalize = async () => {
        try {
            setLoading(true);
            setFinalizingMessage('Please wait while finalizing...');
            
            // Step 1: Mark as SENT
            setFinalizingMessage('Step 1/4: Marking as SENT...');
            await api.markConsignmentAsSent(id);
            await new Promise(resolve => setTimeout(resolve, 1000));
            
            // Step 2: Mark as DISPATCHED
            setFinalizingMessage('Step 2/4: Marking as DISPATCHED...');
            await api.markConsignmentAsDispatched(id);
            await new Promise(resolve => setTimeout(resolve, 1000));
            
            // Step 3: Update received quantities
            setFinalizingMessage('Step 3/4: Updating received quantities...');
            await api.updateAllReceivedQuantities(id);
            await new Promise(resolve => setTimeout(resolve, 1000));
            
            // Step 4: Finalize receiving
            setFinalizingMessage('Step 4/4: Finalizing receiving...');
            await api.finalizeReceiving(id);
            
            // Reload the consignment to get updated status
            const response = await api.getConsignment(id);
            setConsignment(response.data);
            
            // Show success message
            setSuccessMessage('Consignment has been finalized successfully');
            // Clear success message after 5 seconds
            setTimeout(() => setSuccessMessage(''), 5000);
            
        } catch (error) {
            console.error('Error in finalization process:', error);
            setErrorPopup({
                open: true,
                message: `Failed during finalization: ${error.message}`,
                type: 'error'
            });
        } finally {
            setLoading(false);
            setFinalizingMessage('');
            setShowConfirmDialog(false);
        }
    };

    const handleUpdateQuantity = async (productId, count, received) => {
        try {
            console.log('Sending update request:', {
                productId,
                count,
                received
            });

            const response = await api.updateReceivedQuantity(id, productId, {
                count: Number(count),
                received: Number(received)
            });

            console.log('Update response:', response);
            // ... rest of the function
        } catch (error) {
            console.error('Update failed:', error);
            setError(error.message);
        }
    };

    const handleMarkAsSent = async () => {
        try {
            setLoading(true);
            await api.markConsignmentAsSent(id);
            const response = await api.getConsignment(id);
            setConsignment(response);
        } catch (error) {
            setError('Failed to mark as SENT');
        } finally {
            setLoading(false);
        }
    };

    const handleMarkAsDispatched = async () => {
        try {
            setLoading(true);
            await api.markConsignmentAsDispatched(id);
            const response = await api.getConsignment(id);
            setConsignment(response);
        } catch (error) {
            setError('Failed to mark as DISPATCHED');
        } finally {
            setLoading(false);
        }
    };

    const handleReceiveQuantities = async () => {
        try {
            setLoading(true);
            await api.updateAllReceivedQuantities(id);
            
            // Reload the products to show updated quantities
            const updatedProducts = await api.getConsignmentProducts(id);
            setProducts(updatedProducts.data);
            
            setSuccessMessage('All quantities marked as received');
        } catch (error) {
            console.error('Error updating received quantities:', error);
            setErrorPopup({
                open: true,
                message: 'Failed to update received quantities. Please try again.',
                type: 'error'
            });
        } finally {
            setLoading(false);
        }
    };

    // Update keyboard shortcut
    useEffect(() => {
        const handleKeyPress = (event) => {
            // Show debug mode when Ctrl + Shift + + is pressed
            if (event.ctrlKey && event.shiftKey && event.key === '+') {
                setShowDebugSteps(prev => !prev);
            }
        };

        window.addEventListener('keydown', handleKeyPress);
        return () => window.removeEventListener('keydown', handleKeyPress);
    }, []);

    // Add helper function to check if consignment is editable
    const isConsignmentEditable = () => {
        return consignment?.status !== 'RECEIVED';
    };

    // Update the scroll function to use the product ID
    const scrollToModifiedItem = (productId) => {
        if (!tableRef.current) return;
        
        // Find the parent element that contains this size
        const modifiedProduct = products.find(p => p.id === productId);
        if (!modifiedProduct) return;
        
        const parentSku = modifiedProduct.parent_sku;
        const modifiedElement = document.querySelector(`[data-product-id="${parentSku}"]`);
        
        if (modifiedElement) {
            const container = tableRef.current;
            const elementTop = modifiedElement.offsetTop;
            const containerTop = container.scrollTop;
            const containerHeight = container.clientHeight;
            
            container.scrollTo({
                top: elementTop - containerHeight / 3,
                behavior: 'smooth'
            });
        }
    };

    // Update the handleQuantityChange function to log the event
    const handleQuantityChange = async (productId, newValue) => {
        try {
            const updatedValue = parseInt(newValue) || 0;
            const product = products.find(p => p.id === productId);
            
            // Add a guard clause to handle case where product is not found
            if (!product) {
                console.error('Product not found:', productId);
                return;
            }

            const oldValue = product.count || 0;
            
            // Update local state first
            setProducts(products.map(p => 
                p.id === productId 
                    ? { ...p, count: updatedValue }
                    : p
            ));

            // Update the backend
            await api.updateConsignmentProductQuantity(id, productId, updatedValue);
            
            // Log the quantity change event
            logScanEvent({
                type: 'modified',
                sku: product.sku || 'Unknown SKU',
                message: `Quantity changed from ${oldValue} to ${updatedValue}`,
                oldValue,
                newValue: updatedValue
            });

            // After successful update, trigger the highlight and scroll
            setLastModifiedId(null);
            setTimeout(() => {
                setLastModifiedId(productId);
                scrollToModifiedItem(productId);
            }, 50);
            
            setIsSaved(false);
        } catch (error) {
            console.error('Error updating quantity:', error);
            setErrorPopup({
                open: true,
                message: 'Failed to update quantity. Please try again.',
                type: 'error'
            });
        }
    };

    // Update the ScanHistoryDialog component to show the barcode
    const ScanHistoryDialog = () => (
        <Dialog
            open={showScanHistory}
            onClose={() => setShowScanHistory(false)}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <HistoryIcon />
                    Scan History
                </Box>
            </DialogTitle>
            <DialogContent>
                <TableContainer sx={{ maxHeight: 400 }}>
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Time</TableCell>
                                <TableCell>SKU</TableCell>
                                <TableCell>Action</TableCell>
                                <TableCell>Scanned Value</TableCell>
                                <TableCell>Details</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {scanHistory.map((event) => (
                                <TableRow key={event.id}>
                                    <TableCell>{event.timestamp}</TableCell>
                                    <TableCell>{event.sku || '-'}</TableCell>
                                    <TableCell>
                                        <Chip 
                                            size="small"
                                            label={event.type}
                                            color={
                                                event.type === 'success' ? 'success' : 
                                                event.type === 'modified' ? 'primary' : 
                                                event.type === 'aborted' ? 'warning' :
                                                'error'
                                            }
                                            sx={{ 
                                                textTransform: 'capitalize',
                                                bgcolor: event.type === 'modified' ? '#fff3bf' : 
                                                        event.type === 'aborted' ? '#fff3e0' : undefined,
                                                color: (event.type === 'modified' || event.type === 'aborted') ? 'text.primary' : undefined,
                                                border: (event.type === 'modified' || event.type === 'aborted') ? '1px solid' : undefined,
                                                borderColor: 'divider'
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {event.barcode ? (
                                            <Typography 
                                                variant="body2" 
                                                sx={{ 
                                                    fontFamily: 'monospace',
                                                    bgcolor: 'action.hover',
                                                    px: 1,
                                                    py: 0.5,
                                                    borderRadius: 1,
                                                    display: 'inline-block',
                                                    opacity: event.type === 'aborted' ? 0.7 : 1
                                                }}
                                            >
                                                {event.barcode}
                                            </Typography>
                                        ) : '-'}
                                    </TableCell>
                                    <TableCell>
                                        <Typography 
                                            variant="body2" 
                                            color={event.type === 'aborted' ? 'text.secondary' : 'text.primary'}
                                        >
                                            {event.message}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {scanHistory.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={5} align="center">
                                        No history yet
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShowScanHistory(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    );

    // Add a visual indicator of pending scans
    const QueueIndicator = () => {
        if (barcodeQueue.length === 0) return null;
        
        return (
            <Chip
                label={`Processing: ${barcodeQueue.length} item${barcodeQueue.length > 1 ? 's' : ''}`}
                color="primary"
                size="small"
                sx={{ 
                    position: 'absolute',
                    right: -8,
                    top: -8,
                    zIndex: 1,
                    animation: 'pulse 1.5s infinite'
                }}
            />
        );
    };

    // Add this CSS for the pulse animation
    const styles = `
    @keyframes pulse {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    }
    `;

    // Add the styles to the document
    useEffect(() => {
        const styleSheet = document.createElement("style");
        styleSheet.innerText = styles;
        document.head.appendChild(styleSheet);
        return () => styleSheet.remove();
    }, []);

    // Add this new function to handle file processing
    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;
    
        // Check file extension
        const extension = file.name.split('.').pop().toLowerCase();
        if (!['csv', 'xlsx', 'xls'].includes(extension)) {
            setErrorPopup({
                open: true,
                message: 'Please upload a CSV or Excel file',
                type: 'error'
            });
            return;
        }
    
        try {
            // Set initial state
            setIsUploading(true);
            setUploadProgress(0);
            setUploadStatus('Reading file...');

            Papa.parse(file, {
                complete: async (results) => {
                    try {  // Add try-catch inside complete callback
                        const items = results.data
                            .filter(row => row.sku && row.qty)
                            .map(row => ({
                                sku: row.sku.trim(),
                                quantity: parseInt(row.qty) || 1
                            }));

                        if (items.length === 0) {
                            throw new Error('No valid items found in file');
                        }

                        const errors = [];
                        const successes = [];
                        
                        // Process items with progress
                        for (let i = 0; i < items.length; i++) {
                            const item = items[i];
                            setUploadStatus(`Processing ${i + 1} of ${items.length}: ${item.sku}`);
                            setUploadProgress((i / items.length) * 100);

                            try {
                                const productResponse = await api.lookupProductByBarcode(item.sku);
                                if (!productResponse?.data?.data) {
                                    errors.push(`SKU not found: ${item.sku}`);
                                    continue;
                                }

                                const productId = productResponse.data.data.id;
                                await api.addProductToConsignment(id, productId, item.quantity);
                                successes.push(item.sku);

                            } catch (err) {
                                errors.push(`Error processing ${item.sku}: ${err.message}`);
                            }
                        }

                        // Show results
                        if (errors.length > 0) {
                            setErrorPopup({
                                open: true,
                                message: `Processed ${successes.length} items with ${errors.length} errors:\n${errors.join('\n')}`,
                                type: 'warning'
                            });
                        } else {
                            setSuccessMessage(`Successfully added ${successes.length} items`);
                        }

                        // Refresh product list
                        await loadConsignmentAndProducts();

                    } catch (error) {
                        setErrorPopup({
                            open: true,
                            message: error.message,
                            type: 'error'
                        });
                    } finally {
                        // Make sure we reset states in the complete callback
                        setIsUploading(false);
                        setUploadProgress(0);
                        setUploadStatus('');
                    }
                },
                header: true,
                skipEmptyLines: true,
                error: (error) => {
                    setErrorPopup({
                        open: true,
                        message: `Error parsing file: ${error.message}`,
                        type: 'error'
                    });
                    setIsUploading(false);
                    setUploadProgress(0);
                    setUploadStatus('');
                }
            });

        } catch (error) {
            setErrorPopup({
                open: true,
                message: `Error processing file: ${error.message}`,
                type: 'error'
            });
            setIsUploading(false);
            setUploadProgress(0);
            setUploadStatus('');
        }
        
        // Reset file input
        event.target.value = '';
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box p={3}>
                <Alert severity="error">{error}</Alert>
            </Box>
        );
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            {/* Back Button - Moved above header */}
            <Box sx={{ p: 2 }}>
                <Button
                    startIcon={<ArrowBackIcon />}
                    onClick={() => navigate('/consignments')}
                >
                    Back to Consignments
                </Button>
            </Box>

            {/* Mobile Header Toggle */}
            <Box 
                sx={{ 
                    display: { xs: 'flex', md: 'none' },
                    alignItems: 'center',
                    p: 2,
                    borderBottom: 1,
                    borderColor: 'divider',
                    cursor: 'pointer'
                }}
                onClick={() => setHeaderExpanded(!headerExpanded)}
            >
                <Typography variant="subtitle1" sx={{ flex: 1 }}>
                    {consignment?.name || 'Consignment Details'}
                </Typography>
                <IconButton size="small">
                    {headerExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </Box>

            {/* Collapsible Header Content */}
            <Collapse in={headerExpanded || Boolean(window.innerWidth >= 900)}>
                <Paper sx={{ 
                    p: 2, 
                    mb: 2,
                    display: { xs: headerExpanded ? 'block' : 'none', md: 'block' }
                }}>
                    <Grid container spacing={2}>
                        {/* Name/PO Reference */}
                        <Grid item xs={12} md={4}>
                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                Name / PO Reference
                            </Typography>
                            <TextField
                                value={consignment?.name || ''}
                                onChange={(e) => {
                                    setConsignment(prev => ({ ...prev, name: e.target.value }));
                                    setIsSaved(false);
                                }}
                                onBlur={async () => {
                                    try {
                                        await api.updateConsignmentName(id, consignment.name);
                                        setIsSaved(true);
                                    } catch (err) {
                                        console.error('Error updating name:', err);
                                        setError('Failed to update name');
                                    }
                                }}
                                size="small"
                                fullWidth
                                sx={{
                                    '& .MuiInputBase-input': {
                                        cursor: 'pointer',
                                    }
                                }}
                                variant="outlined"
                            />
                        </Grid>

                        {/* Number */}
                        <Grid item xs={12} md={4}>
                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                Number
                            </Typography>
                            <TextField
                                value={consignment?.reference || ''}
                                onChange={(e) => {
                                    setConsignment(prev => ({ ...prev, reference: e.target.value }));
                                    setIsSaved(false);
                                }}
                                onBlur={async () => {
                                    try {
                                        await api.updateConsignmentReference(id, consignment.reference);
                                        setIsSaved(true);
                                    } catch (err) {
                                        console.error('Error updating reference:', err);
                                        setError('Failed to update reference');
                                    }
                                }}
                                size="small"
                                fullWidth
                                sx={{
                                    '& .MuiInputBase-input': {
                                        cursor: 'pointer',
                                    }
                                }}
                                variant="outlined"
                            />
                        </Grid>

                        {/* Status */}
                        <Grid item xs={12} md={4}>
                            <Typography variant="subtitle2" color="text.secondary">
                                Status
                            </Typography>
                            <Typography variant="body1">
                                {consignment?.status || 'N/A'}
                            </Typography>
                        </Grid>

                        {/* Date Created */}
                        <Grid item xs={12} md={4}>
                            <Typography variant="subtitle2" color="text.secondary">
                                Date Created
                            </Typography>
                            <Typography variant="body1">
                                {consignment?.created_at 
                                    ? new Date(consignment.created_at).toLocaleDateString() 
                                    : 'N/A'}
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Collapse>

            {/* Quick Summary for Mobile (Always Visible) */}
            <Box 
                sx={{ 
                    display: { xs: 'flex', md: 'none' },
                    p: 1,
                    bgcolor: 'background.paper',
                    borderBottom: 1,
                    borderColor: 'divider',
                    justifyContent: 'space-between'
                }}
            >
                <Typography variant="body2">
                    Status: <strong>{consignment?.status || 'N/A'}</strong>
                </Typography>
                <Typography variant="body2">
                    Ref: <strong>{consignment?.reference || 'N/A'}</strong>
                </Typography>
            </Box>

            {/* Show success message if present */}
            {successMessage && (
                <Alert 
                    severity="success" 
                    sx={{ 
                        position: 'fixed', 
                        top: 16, 
                        right: 16, 
                        zIndex: 9999,
                        animation: 'fadeIn 0.5s'
                    }}
                >
                    {successMessage}
                </Alert>
            )}

            {/* Show finalizing message if present */}
            {finalizingMessage && (
                <Alert 
                    severity="info"
                    sx={{ 
                        position: 'fixed', 
                        top: '50%', 
                        left: '50%', 
                        transform: 'translate(-50%, -50%)',
                        zIndex: 9999,
                        minWidth: 300,
                        textAlign: 'center'
                    }}
                >
                    <CircularProgress size={20} sx={{ mr: 2 }} />
                    {finalizingMessage}
                </Alert>
            )}

                {/* Error Popup */}
            <ErrorPopup
                open={errorPopup.open}
                message={errorPopup.message}
                type={errorPopup.type}
                onConfirm={errorPopup.onConfirm}
                onClose={() => setErrorPopup({ 
                    open: false, 
                    message: '', 
                    type: 'error',
                    onConfirm: null 
                })}
            />
            
            {/* Main content area with fixed header */}
            <Box sx={{ flex: 1, overflow: 'hidden', mb: 2 }}>
                <TableContainer 
                    ref={tableRef}
                    sx={{
                        height: '100%',
                        overflow: 'auto',
                        '& th': {
                            backgroundColor: 'background.paper',
                            position: 'sticky',
                            top: 0,
                            zIndex: 1
                        }
                    }}
                >
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Parent SKU</TableCell>
                                <TableCell>Product Name</TableCell>
                                <TableCell>Total</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {consolidateProducts(products).map((consolidatedProduct, index) => (
                                <React.Fragment key={consolidatedProduct.parentSku}>
                                    {index > 0 && (
                                        <TableRow sx={{ height: '8px' }}>
                                            <TableCell 
                                                colSpan={5} 
                                                sx={{ 
                                                    padding: 0,
                                                    border: 'none',
                                                    bgcolor: 'background.paper'
                                                }} 
                                            />
                                        </TableRow>
                                    )}
                                    
                                    {/* Main product row */}
                                    <TableRow 
                                        data-product-id={consolidatedProduct.parentSku}
                                        sx={{
                                            bgcolor: '#f0f7ff',
                                            '& > td': {
                                                borderTop: '1px solid rgba(224, 224, 224, 1)',
                                                borderLeft: '1px solid rgba(224, 224, 224, 1)',
                                                borderRight: '1px solid rgba(224, 224, 224, 1)',
                                                padding: '8px',
                                                paddingBottom: '4px',
                                            }
                                        }}
                                    >
                                        <TableCell>{consolidatedProduct.parentSku}</TableCell>
                                        <TableCell>{consolidatedProduct.baseProduct.name}</TableCell>
                                        <TableCell>
                                            <Typography sx={{ fontWeight: 'bold' }}>
                                                {Object.values(consolidatedProduct.sizes)
                                                    .reduce((sum, size) => sum + (parseInt(size.count) || 0), 0)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>{consolidatedProduct.baseProduct.status}</TableCell>
                                        <TableCell>
                                            <IconButton 
                                                onClick={() => handleDeleteProduct(consolidatedProduct.parentSku)}
                                                size="small"
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>

                                    {/* Sizes row */}
                                    <TableRow sx={{
                                        bgcolor: '#f0f7ff',
                                        '& > td': {
                                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                            borderLeft: '1px solid rgba(224, 224, 224, 1)',
                                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                                            padding: '8px',
                                            paddingTop: '4px',
                                        }
                                    }}>
                                        <TableCell colSpan={5}>
                                            <Box 
                                                sx={{ 
                                                    display: 'flex',
                                                    flexDirection: { xs: 'column', md: 'row' },
                                                    gap: 1,
                                                    flexWrap: { xs: 'nowrap', md: 'wrap' },
                                                    pl: 1,
                                                    py: 0.5,
                                                    mx: 1
                                                }}
                                            >
                                                {Object.entries(consolidatedProduct.sizes)
                                                    .sort(([sizeA], [sizeB]) => parseFloat(sizeA) - parseFloat(sizeB))
                                                    .map(([size, details]) => {
                                                        const isModified = details.id === lastModifiedId;
                                                        
                                                        return (
                                                            <Box 
                                                                key={size}
                                                                sx={{
                                                                    display: 'flex',
                                                                    flexDirection: { xs: 'row', md: 'column' },
                                                                    alignItems: 'center',
                                                                    justifyContent: { xs: 'space-between', md: 'center' },
                                                                    gap: 1,
                                                                    minWidth: { xs: '100%', md: '60px' },
                                                                    bgcolor: isModified ? '#fff3bf' : 'transparent',
                                                                    padding: 1,
                                                                    borderRadius: 1,
                                                                    borderBottom: { xs: '1px solid rgba(224, 224, 224, 0.3)', md: 'none' },
                                                                    '&:last-child': {
                                                                        borderBottom: 'none'
                                                                    }
                                                                }}
                                                            >
                                                                <Typography 
                                                                    variant="body2" 
                                                                    sx={{ 
                                                                        fontWeight: 'bold',
                                                                        color: isModified ? 'primary.main' : 'inherit',
                                                                        minWidth: { xs: '60px', md: 'auto' }
                                                                    }}
                                                                >
                                                                    {size}
                                                                </Typography>
                                                                <TextField
                                                                    type="number"
                                                                    value={details.count || ''}
                                                                    onChange={(e) => handleQuantityChange(details.id, e.target.value)}
                                                                    disabled={!isConsignmentEditable()}
                                                                    size="small"
                                                                    sx={{ 
                                                                        width: '80px',
                                                                        '& input': { 
                                                                            textAlign: 'center',
                                                                            p: '4px',
                                                                            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                                                                                '-webkit-appearance': 'none',
                                                                                margin: 0
                                                                            },
                                                                            '&[type=number]': {
                                                                                '-moz-appearance': 'textfield'
                                                                            }
                                                                        },
                                                                        '& .MuiInputBase-root': {
                                                                            bgcolor: isModified ? '#fff3bf' : 'background.paper',
                                                                            height: '30px'
                                                                        }
                                                                    }}
                                                                    InputProps={{
                                                                        sx: { height: '30px' }
                                                                    }}
                                                                />
                                                            </Box>
                                                        );
                                                    })}
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            {/* Bottom Action Bar */}
            <Paper 
                elevation={3} 
                sx={{ 
                    position: 'sticky',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    p: 2,
                    backgroundColor: 'background.paper',
                    borderTop: 1,
                    borderColor: 'divider',
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    justifyContent: 'space-between',
                    alignItems: { xs: 'stretch', md: 'center' },
                    gap: 2
                }}
            >
                <Box sx={{ 
                    display: 'flex', 
                    gap: 3, 
                    alignItems: 'center',
                    justifyContent: { xs: 'space-around', md: 'flex-start' } 
                }}>
                    <Box>
                        <Typography variant="subtitle2" color="text.secondary">
                            Total SKUs
                        </Typography>
                        <Typography variant="h6">
                            {totalSkus}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="subtitle2" color="text.secondary">
                            Total Quantity
                        </Typography>
                        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                            {totalQuantity}
                        </Typography>
                    </Box>
                </Box>

                <Box sx={{ 
                    display: 'flex', 
                    gap: 2,
                    flexDirection: { xs: 'column', md: 'row' },
                    width: { xs: '100%', md: 'auto' } 
                }}>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', position: 'relative' }}>
                        <TextField
                            ref={barcodeInputRef}
                            label="Scan Barcode"
                            value={barcode}
                            onChange={(e) => setBarcode(e.target.value)}
                            onKeyDown={handleBarcodeScan}
                            size="small"
                            sx={{ flex: 1 }}
                        />
                        <QueueIndicator />
                        
                        <Box sx={{ position: 'relative' }}>
                            <Tooltip title="Upload SKU List (CSV format: sku,qty)">
                                <IconButton
                                    component="label"
                                    size="small"
                                    disabled={isUploading}
                                    sx={{ 
                                        bgcolor: 'action.selected',
                                        '&:hover': { bgcolor: 'action.focus' }
                                    }}
                                >
                                    <UploadFileIcon />
                                    <input
                                        type="file"
                                        hidden
                                        accept=".csv,.xlsx,.xls"
                                        onChange={handleFileUpload}
                                        disabled={isUploading}
                                    />
                                </IconButton>
                            </Tooltip>
                            {isUploading && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '100%',
                                        right: 0,
                                        mt: 1,
                                        p: 1.5,
                                        bgcolor: 'background.paper',
                                        borderRadius: 1,
                                        boxShadow: 3,
                                        zIndex: 1000,
                                        width: 250,
                                    }}
                                >
                                    <Typography variant="caption" display="block" gutterBottom>
                                        {uploadStatus}
                                    </Typography>
                                    <LinearProgress 
                                        variant="determinate" 
                                        value={uploadProgress}
                                        sx={{ mb: 1 }}
                                    />
                                    <Typography variant="caption" color="text.secondary">
                                        {Math.round(uploadProgress)}%
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                        
                        <Tooltip title="Scan History">
                            <IconButton 
                                onClick={() => setShowScanHistory(true)}
                                size="small"
                                sx={{ 
                                    bgcolor: 'action.selected',
                                    '&:hover': { bgcolor: 'action.focus' }
                                }}
                            >
                                <HistoryIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    
                    <Button
                        variant="contained"
                        disabled={isSaved || !isConsignmentEditable()}
                        onClick={() => setIsSaved(true)}
                    >
                        {isSaved ? 'Saved' : 'Save'}
                    </Button>

                    <Button
                        variant="outlined"
                        color="error"
                        onClick={handleCancel}
                        disabled={!isConsignmentEditable()}
                    >
                        Cancel
                    </Button>

                    {isConsignmentEditable() && (
                        <>
                            <Button
                                onClick={handleFinalizeClick}
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                size="large"
                            >
                                Finalize Receiving
                            </Button>

                            {/* Debug steps - now in a Portal or at the bottom of the page */}
                            {showDebugSteps && (
                                <Box sx={{ 
                                    position: 'fixed',
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    bgcolor: 'grey.100',
                                    p: 2,
                                    borderTop: 1,
                                    borderColor: 'grey.300',
                                    zIndex: 1000
                                }}>
                                    <Typography variant="caption" sx={{ width: '100%', color: 'text.secondary' }}>
                                        Debug Steps (Ctrl + Shift + + to hide):
                                    </Typography>
                                    <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            onClick={handleMarkAsSent}
                                        >
                                            1. Mark as SENT
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            onClick={handleMarkAsDispatched}
                                        >
                                            2. Mark as DISPATCHED
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            onClick={handleReceiveQuantities}
                                        >
                                            3. RECEIVE QTYS
                                        </Button>
                                    </Box>
                                </Box>
                            )}
                        </>
                    )}

                    <ErrorPopup
                        open={showConfirmDialog}
                        onClose={() => setShowConfirmDialog(false)}
                        onConfirm={handleFinalize}
                        type="warning"
                        title="Confirm Finalize"
                        message="Are you sure you want to finalize this consignment? This will mark it as SENT, DISPATCHED, update quantities, and finally mark as RECEIVED. This action cannot be undone."
                        showCancel={true}
                    />
                </Box>
            </Paper>

            {/* Add the dialog to your JSX */}
            <ScanHistoryDialog />

            <UploadProgressDialog 
                isUploading={isUploading}
                uploadStatus={uploadStatus}
                uploadProgress={uploadProgress}
            />
        </Box>
    );
};

export default ConsignmentView;
