import React from 'react';
import { Snackbar, Alert } from '@mui/material';

const SuccessNotification = ({ open, message, onClose }) => {
    return (
        <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={onClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert 
                onClose={onClose} 
                severity="success" 
                variant="filled"
                sx={{ width: '100%' }}
            >
                {message}
            </Alert>
        </Snackbar>
    );
};

export default SuccessNotification;
